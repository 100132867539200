/* Tailwind setup */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Light Mode Variables (Default) */
:root {
  --bg-color: #ffffff;
  --text-color: #000000; /* Black text on white background for sufficient contrast */
  --highlight-color: #e0b030; /* Darker yellow for contrast */
  --link-color: #ffffff; /* Keep link text white */
  --hover-color: #ffd700; /* Ensure yellow hover color */
}

.no-hover:hover {
  background-color: transparent !important;
  color: inherit !important;
}


/* Dark Mode Variables */
[data-theme="dark"] {
  --bg-color: #1a1a2e;
  --text-color: #e0e0e0; /* Lighter text color for dark mode */
  --highlight-color: #ffd700; /* Gold marker for dark mode */
  --link-color: #ffffff; /* White link color for better readability in dark mode */
  --button-bg-inactive: #2d2d44; /* Dark gray background for inactive buttons */
  --button-text-inactive: #b0b0b0; /* Lighter gray text for better contrast */
  --button-text-active: #ffffff; /* Ensure bright text on active buttons */
}


/* General Styles */
html {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px; /* Set base font size to 16px */
  height: 100%; /* Added height for root layout */
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 16px; /* Ensure consistent 16px font size */
  height: 100%;
}

#root, .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  flex: 1; /* Makes the content area grow and take the available space */
}

/* Typography Adjustments */
h1, h2, h3, p {
  margin: 0;
  line-height: 1.5;
  color: var(--text-color);
  font-size: 1rem; /* Ensure 16px font size for headings */
}

/* Link Styles */
a {
  text-decoration: none; /* Remove underline */
  color: var(--link-color); /* White link color for readability */
  font-size: 1rem; /* 16px equivalent for links */
  transition: color 0.3s ease; /* Smooth transition on hover */
}

.language-bar:hover {
  background-color: transparent; /* No background color change on hover */
  color: inherit; /* Inherit current color */
}

a:hover {
  color: var(--hover-color); /* Gold hover color */
}

/* Active Link Styling */
.link-active {
  color: var(--highlight-color); /* Highlight active link */
}

/* Flex Layout */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.min-h-screen {
  min-height: 100vh;
}

.mt-auto {
  margin-top: auto;
}

/* Header and SideMenu Styling */
.header, .side-menu {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 1rem;
  font-size: 1rem; /* Ensure consistent 16px text size */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* SideMenu Styles */
.side-menu {
  position: fixed; /* Make it overlay the screen */
  top: 0;
  left: 0;
  height: 100vh; /* Full height */
  width: 100%; /* Full width for mobile */
  background-color: var(--bg-color);
  z-index: 40; /* Ensure it stays on top */
  transform: translateX(-100%); /* Hidden by default */
  transition: transform 0.3s ease-in-out; /* Smooth slide-in animation */
  display: flex;
  flex-direction: column;
}

.side-menu.visible {
  transform: translateX(0); /* Slide in when visible */
}

/* Menu List */
.menu-list {
  list-style-type: none;
  padding: 2rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* Spacing between items */
}

.menu-item {
  display: flex;
  align-items: center;
  color: var(--link-color);
  font-size: 1.25rem; /* Slightly larger text for better visibility */
  transition: color 0.3s ease;
}

.menu-item:hover {
  color: var(--hover-color); /* Gold hover color */
}

/* Icons in Menu Items */
.menu-item .icon {
  margin-right: 1rem;
  font-size: 1.5rem; /* Larger icons for better visibility */
}

/* Responsive Text */
.menu-text {
  display: inline; /* Show text by default */
  color: var(--link-color);
}

/* Adjust styles for larger screens */
@media (min-width: 640px) {
  .side-menu {
    position: relative;
    width: 16rem; /* Fixed width for larger screens */
    height: 100vh; /* Full height */
    transform: none; /* Always visible */
    transition: none; /* No animation needed */
  }

  .menu-list {
    padding: 1rem;
  }

  .menu-text {
    display: inline; /* Always show text on larger screens */
  }
}

/* Hover Effects for Links */
.menu-list li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--link-color);
  transition: color 0.3s ease;
}

.menu-list li a:hover {
  color: var(--hover-color); /* Gold hover */
}

.link-active {
  color: var(--highlight-color); /* Gold color for active links */
}


/* Button Styles */
.btn {
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.btn-active {
  background-color: var(--highlight-color);
  color: #000;
}

.btn:hover {
  background-color: var(--hover-color); /* Ensure yellow hover color */
}

/* Inactive Button Styles */
.btn-inactive {
  background-color: var(--button-bg-inactive);
  color: var(--button-text-inactive);
  cursor: not-allowed;
}

.btn-inactive:hover {
  background-color: var(--button-bg-inactive); /* Prevent hover color change */
  color: var(--button-text-inactive);
}


/* Tooltip Styling */
.custom-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background for the tooltip */
  color: #fff; /* White text */
  padding: 5px 10px; /* Padding for the tooltip */
  border-radius: 4px;
  font-size: 12px; /* Font size for tooltip */
  top: -35px; /* Position the tooltip above the button */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; /* Prevent line breaks */
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

button:hover + .custom-tooltip {
  opacity: 1;
  visibility: visible;
}

/* Form Section Style for "Sign Me Up" button and email input */
.form-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-section input[type="email"] {
  max-width: 100%;
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 1rem;
  box-sizing: border-box;
}

.form-section button {
  max-width: 100%;
  padding: 0.75rem;
  background-color: var(--highlight-color);
  color: white;
  font-weight: bold;
  border-radius: 8px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
  box-sizing: border-box;
}

button:hover {
  background-color: var(--hover-color); /* Ensure good contrast on hover */
}

/* Chart Styles */
.chart-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.chart-wrapper {
  width: 100%;
  max-width: 800px;
  height: 400px;
}

.chart-grid {
  stroke: var(--chart-grid-color);
}

.chart-tooltip {
  background-color: var(--chart-tooltip-bg);
  color: var(--chart-tooltip-text);
  border: 1px solid var(--chart-border-color);
}

.chart-legend {
  color: var(--chart-legend-color);
}

.chart-doughnut {
  background-color: var(--chart-bg-color);
  border-color: var (--chart-border-color);
}

/* Ticks (figures) for charts */
.chart-tick {
  color: var (--chart-tick-color);
}

/* Typography for Headings */
.heading-style {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  letter-spacing: -0.02em;
  color: var (--text-color);
}

.subheading-style {
  font-weight: 600;
  text-align: center;
  font-size: 1.25rem;
}

.text-lg {
  text-align: center;
  font-size: 1rem;
  line-height: 1.4;
}

/* Apply text colors dynamically using CSS variables */
.text-dynamic {
  color: var(--text-color);
}


/* Shared Gradient Background */
.bg-gradient-indigo-purple {
  background-image: linear-gradient(to right, #5a67d8, #805ad5);
}

/* Shared Shadow Effect */
.shadow-effect {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Input Field (Email) */
input[type="email"] {
  padding: 0.75rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 100%;
  font-size: 1rem;
}

/* Mobile Optimizations */
@media (max-width: 640px) {
  .heading-style {
    font-size: 1.75rem; /* Smaller for mobile */
  }

  .text-lg {
    font-size: 1rem;
  }

  .container {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .donut-chart-wrapper {
    width: 100%;
    height: 240px;
    justify-content: center;
  }

  .donut-chart {
    width: 220px;
    height: 220px;
    margin: 0 auto;
  }
}

/* Galaxy Z Fold 5 Compatibility */
@media (max-width: 344px) {
  .heading-style {
    font-size: 1.2rem;
    text-align: center;
  }

  .text-lg {
    font-size: 0.75rem;
    text-align: center;
    line-height: 1.3;
  }

  .form-section input[type="email"],
  .form-section button {
    width: 90%;
    max-width: 100%;
    padding: 0.75rem;
  }

  .donut-chart-wrapper {
    width: 100%;
    height: 200px;
  }

  .donut-chart {
    width: 200px;
    height: 200px;
  }

  .custom-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Large Screen (Unfolded State) */
@media (min-width: 1812px) {
  .heading-style {
    font-size: 4rem;
  }

  .text-lg {
    font-size: 1.5rem;
  }

  .container {
    max-width: 1720px;
  }

  .donut-chart-wrapper {
    height: 500px;
  }

  .donut-chart {
    width: 450px;
    height: 450px;
  }
}


